import styled from 'styled-components';

const Content = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding: 40px 40px;
  box-sizing: border-box;

  @media screen and (min-width: 600px) {
    padding: 40px 80px;
  }
`;

export default Content;
