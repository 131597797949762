import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { darken } from 'polished';

import {
  GlobalStyles, Icon, List, Flex,
  getSpacing, getColor, Box, Text,
} from 'style-guide';

import Content from '../content';
import Footer from '../footer';
import Header from '../header/header';
import '../../fonts/inter.css';

const contacts = [
  { iconName: 'github', link: 'http://github.com/jmlavoier' },
  { iconName: 'email', link: 'mailto:joao.lavoier@gmail.com' },
  { iconName: 'linkedin', link: 'http://linkedin.com/in/jmlavoier' },
  { iconName: 'twitter', link: 'https://twitter.com/JoaoLavoier' },
];

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Content>
        <main>{children}</main>
      </Content>
      <Footer>
        <Content>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Box mb={getSpacing('tight')}>
              <List
                data={contacts}
                listStyle="none"
                flex
                renderItems={({ iconName, link }, idx) => (
                  <List.Item ml={idx === 0 ? '0' : getSpacing()}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <Icon
                        name={iconName}
                        color={getColor('white')}
                        hoverColor={getColor('white', darken(0.2))}
                      />
                    </a>
                  </List.Item>
                )}
              />
            </Box>
            <Box>
              <Text color={getColor('springWood')} type="caption">João Lavoier ® 2019</Text>
            </Box>
          </Flex>
        </Content>
      </Footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
