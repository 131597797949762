import React from 'react';
import pt from 'prop-types';

import {
  Title, Text, Box,
  getColor,
} from 'style-guide';

const Section = ({ title, description, children }) => (
  <section>
    <Title.Medium
      color={getColor('gray')}
    >
      {title}
    </Title.Medium>
    {description && (
      <Box>
        <Text type="body">{description}</Text>
      </Box>
    )}
    {children}
  </section>
);

Section.propTypes = {
  title: pt.string.isRequired,
  description: pt.string,
  children: pt.node.isRequired,
};

Section.defaultProps = {
  description: '',
};

export default Section;
