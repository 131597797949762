import styled from 'styled-components';

import positionProps from './utils/positions';
import { getSpacingProps } from './utils/spacing';

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  ${({ absolute }) => absolute && 'position: absolute;'}
  ${({ fixed }) => fixed && 'position: fixed;'}
  ${({ relative }) => relative && 'position: relative;'}

  ${getSpacingProps}
  ${positionProps}
`;

export default Flex;
