const positions = [
  'top',
  'left',
  'bottom',
  'right',
];

const positionProps = (props) => positions
  .reduce((styles, position) => (
    props[position]
      ? `${styles}
         ${position}: ${props[position]};`
      : styles),
  '');

export default positionProps;
