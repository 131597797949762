import styled, { css } from 'styled-components';

import positionProps from './utils/positions';
import { getSpacingProps } from './utils/spacing';

const Box = styled.div`
  flex-grow: ${({ grow }) => grow || '1'};
  ${({ width }) => width && css`width: ${width};`}
  ${({ height }) => height && css`height: ${height};`}
  ${({ alignSelf }) => alignSelf && css`align-self: ${alignSelf};`}
  ${({ absolute }) => absolute && 'position: absolute;'}
  ${({ fixed }) => fixed && 'position: fixed;'}
  ${({ relative }) => relative && 'position: relative;'}

  ${getSpacingProps}
  ${positionProps}
`;

export default Box;
