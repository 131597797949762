import styled, { css } from 'styled-components';

import { getSpacingProps } from './utils/spacing';
import getColor from './utils/colors';

const textFormats = ({ type }) => ({
  heading: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `,
  subheading: css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
  `,
  body: css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  `,
  caption: css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  `,
}[type]);

const Text = styled.span`
  ${textFormats}
  color: ${({ color = getColor('text') }) => color};
  align: ${({ align }) => align || 'left'};

  ${getSpacingProps}
`;

export default Text;
