import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Link, Title, Flex, Box,
  getColor, getSpacing,
} from 'style-guide';

import Content from '../content';
import { Wrapper, ProfileImg } from './header.style';

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fixed(width: 180, height: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Content>
        <Flex
          justifyContent="center"
          flexDirection="column"
          mt={getSpacing('loose')}
        >
          <ProfileImg
            fixed={data.placeholderImage.childImageSharp.fixed}
          />
          <Box mt={getSpacing('default')}>
            <Link
              to="/"
            >
              <Title.Medium color={getColor('royalBlue')}>
                {siteTitle}
              </Title.Medium>
            </Link>
          </Box>
          <Box mt={getSpacing('default')}>
            <Title.Tiny color={getColor('gray')} align="center">
              Software Engineer.  Frontend Specialist.  JavaScript Developer.  FP Enthusiastic
            </Title.Tiny>
          </Box>
        </Flex>
      </Content>
    </Wrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
