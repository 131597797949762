const directions = [
  { name: 'top', prefix: 't' },
  { name: 'bottom', prefix: 'b' },
  { name: 'left', prefix: 'l' },
  { name: 'right', prefix: 'r' },
];

const spacings = [
  { name: 'margin', prefix: 'm' },
  { name: 'padding', prefix: 'p' },
];

const makeSpacingProps = (arr1, arr2) => arr1.reduce(
  (acc1, arr1Item) => arr2.reduce((acc2, arr2Item) => ({
    ...acc1,
    ...acc2,
    [`${arr1Item.prefix}${arr2Item.prefix}`]: `${arr1Item.name}-${arr2Item.name}`,
  }), {}), {},
);

const spacingProps = makeSpacingProps(spacings, directions);

export const getSpacingProps = (props) => Object.keys(spacingProps)
  .reduce((style, marginType) => (
    props[marginType]
      ? `${style}
      ${spacingProps[marginType]}: ${props[marginType]};`
      : style),
  '');

export const spacing = {
  none: '0',
  'extra-tight': '4px',
  tight: '8px',
  'base-tight': '12px',
  default: '16px',
  loose: '20px',
  'extra-loose': '32px',
};

const getSpacing = (type = 'default') => spacing[type];

export default getSpacing;
