import styled, { css } from 'styled-components';

import { getSpacingProps } from './utils/spacing';
import getColor from './utils/colors';

const alignProp = ({ align = 'left' }) => css`text-align: ${align};`;
const colorProp = ({ color = getColor('text') }) => css`color: ${color};`;
const reset = css`
  margin: 0;
`;

const commonsText = css`
  ${reset}
  ${colorProp}
  ${alignProp}
  ${getSpacingProps}
`;

const Title = styled.h1`
  ${commonsText}

  font-size: 42px;
  line-height: 44px;
  font-weight: 500;
`;

Title.Large = styled.h1`
  ${commonsText}

  font-size: 42px;
  line-height: 44px;
  font-weight: 500;
`;

Title.Medium = styled.h2`
  ${commonsText}

  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
`;

Title.Small = styled.h3`
  ${commonsText}

  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
`;

Title.Tiny = styled.h4`
  ${commonsText}

  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
`;

export default Title;
