import React from 'react';
import styled, { css } from 'styled-components';
import {
  arrayOf, func, bool, string, object, oneOfType,
} from 'prop-types';

import { getSpacingProps } from './utils/spacing';

const ListStyle = styled.ul`
  margin: 0;
  padding: 0;
  ${({ listStyle }) => listStyle && css`list-style: ${listStyle}`};
  ${({ flex }) => flex && css`display: flex;`}
  
  ${getSpacingProps}
`;

const Item = styled.li`
  ${({ inline }) => inline && css`display: inline;`}

  ${getSpacingProps}
`;

const List = ({
  data, renderItems, flex, ...props
}) => (
  <ListStyle flex={flex} {...props}>
    {data.map((item, idx) => renderItems(item, idx))}
  </ListStyle>
);

List.propTypes = {
  data: arrayOf(oneOfType(string, object)).isRequired,
  flex: bool,
  renderItems: func.isRequired,
};

List.defaultProps = {
  flex: false,
};


List.Item = Item;

export default List;
