import React from 'react';
import { string, node } from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';

import getColor from './utils/colors';

const colorProp = ({
  color = getColor('gray'),
  hoverColor = getColor('royalBlue'),
}) => css`
  color: ${color};

  &:active {
    color: ${color};
  }

  &:visited {
    color: ${color};
  }

  &:hover {
    color: ${hoverColor === 'none' ? color : hoverColor};
  }

  &:focus {
    color: ${hoverColor === 'none' ? color : hoverColor};
  }
`;


const hoverColorProp = ({
  color = getColor('gray'),
  hoverColor = getColor('royalBlue'),
}) => css`
  &:hover {
    color: ${hoverColor === 'none' ? color : hoverColor};
  }

  &:focus {
    color: ${hoverColor === 'none' ? color : hoverColor};
  }
`;

const LinkRoot = styled(GatsbyLink)`
  font-family: Inter, Helvetica;
  text-decoration: none;
  ${colorProp}
`;

const LinkWrapper = ({ hoverColor, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <><LinkRoot {...props}>{children}</LinkRoot></>
);

LinkWrapper.propTypes = {
  hoverColor: string,
  children: node.isRequired,
};

LinkWrapper.defaultProps = {
  hoverColor: 'none',
};


const Link = styled(LinkWrapper)`
  ${hoverColorProp}
`;


export default Link;
