import React from 'react';
import styled, { css } from 'styled-components';
import P from 'prop-types';

import getIconPath from './utils/icons';
import getColor from './utils/colors';

const Svg = styled.svg.attrs(({ viewBox = '0 0 24 24', width, height }) => ({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox,
  width,
  height,
}))`
  fill: ${({ color }) => color};
  
  ${({ hoverColor }) => css`
    &:hover {
      fill: ${hoverColor};
    }
  `}
`;

const Icon = ({
  name, width, height, color, hoverColor, ...props
}) => (
  <Svg width={width} height={height} color={color} hoverColor={hoverColor} {...props}>
    <path d={getIconPath(name)} />
  </Svg>
);

Icon.propTypes = {
  name: P.string.isRequired,
  width: P.string,
  height: P.string,
  color: P.string,
  hoverColor: P.string,
};

Icon.defaultProps = {
  width: '24',
  height: '24',
  color: getColor('royalBlue'),
  hoverColor: getColor('royalBlue'),
};

export default Icon;
