import styled from 'styled-components';

import Img from 'gatsby-image';
import { getColor } from 'style-guide';

export const Wrapper = styled.header`
  height: 430px;
  background-color: ${getColor('dark')};
`;

export const ProfileImg = styled(Img)`
  border-radius: 50%;
`;
